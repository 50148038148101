import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Container } from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import { useLocation } from "react-router-dom";

export default function DetailedBlog() {
  const location = useLocation();
  const { data } = location.state;

  useEffect(() => {
    const slideCategoryId = window.location.hash.substring(1);

    window.scrollTo({ top: 0, behavior: "smooth" });

    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  }, []);

  // function formatDate(inputDate) {
  //   if (inputDate === '0000-00-00') {
  //     return '0000-00-00';
  //   }
  //   const dateObject = new Date(inputDate);
  //   const months = [
  //     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //     'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  //   ];
  //   const month = months[dateObject.getMonth()];
  //   const day = dateObject.getDate();
  //   const year = dateObject.getFullYear();
  //   const formattedDate = `${month} ${day}, ${year}`;
  //   return formattedDate;
  // }

  return (
    <Box className="Banner">
      <Container>
        <Box mt={6} display="flex" justifyContent="center">
          <Typography variant="h3" fontWeight={600}>
            {data?.blogTitle}
          </Typography>
        </Box>

        <Grid
          container
          mt={{ lg: 5, md: 5, sm: 8, xs: 2 }}
          spacing={{ lg: 0, md: 0, sm: 3, xs: 1 }}
          display="flex"
          justifyContent={{ lg: "end", md: "end", sm: "center", xs: "center" }}
          alignContent="start"
          alignItems="start"
        >
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            justifyContent="start"
          >
            <img
              src={data?.blogImage}
              alt=""
              style={{ width: "100%", maxHeight: 400, objectFit: "contain" }}
            />
            <Stack spacing={1.5} mt={4} textAlign="left" p={3}>
              <Typography variant="subtitle2" fontWeight={700}>
                Updated On
              </Typography>
              <Typography variant="subtitle2" fontWeight={700}>
                {data?.blogYear}
              </Typography>
              <Stack textAlign="left" spacing={2} p={2}>
                <Box>
                  <Typography variant="h6" fontWeight={600} lineHeight={1.8}>
                    {data?.blogContent}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
}
