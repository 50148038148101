import React from 'react'
import { Avatar, Box, Card,  Grid,  Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ProfileImage from '../../Assets/icon.png';
import MovieCard from '../../Components/Cards/MovieCard';

export default function ResultsCard({RightName , setIsLoading , isLoading , MovieList}) {


    const navigate = useNavigate();
    
        const shuffleArray = (array) => {
          const shuffledArray = array.slice();
          for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
          }
          return shuffledArray;
        };

        const shuffledMovieList = shuffleArray(MovieList);        

       
  return (
    <Box mt={5} mb={1} textAlign='left' bgcolor='#fff' p={4} borderRadius='20px' boxShadow={7}>
    <Typography variant='h5' fontWeight={600} mb={1.5} color='#46344E'>
      {RightName} Rights
      </Typography>
      

        <Grid container spacing={2}>
       {shuffledMovieList.slice(0, 4).map((i) => {
        return (
        <Grid item xs={6} sm={6} md={3} lg={3}  sx={{cursor:'pointer'}} onClick={()=>navigate(`/livedetail/movieresults/${i.activeMovieId}/${i.movieTitle.replace(/\s+/g, '').toLowerCase()}`)}>
        <MovieCard i={i} />
        </Grid>
        )})
        }
        </Grid>
    </Box>
  )
}
