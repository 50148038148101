import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../../Components/Footer/Footer";
import "../MarketPlace/MarketPlace.css";
import { fetch_get_active_movies } from "../../Utils/Services/MovieService";
import { useLocation } from "react-router-dom";
import ResultsCard from "./ResultsCard";

export default function MovieResults() {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [liveData, setLiveData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const searchText = localStorage.getItem("searchText");
  const [noResults, setNoResults] = useState(false);

  const getAllData = async () => {
    if (searchText !== "" && searchText !== null && searchText !== undefined) {
      try {
        const responseData = await fetch_get_active_movies(
          "",
          "",
          "",
          "",
          "",
          searchText,
          ""
        );
        if (!responseData.error) {
          setLiveData(responseData.data);
          setIsLoading(false);
          setNoResults(false);
        } else {
          setNoResults(true);
          setIsLoading(false);
        }
      } catch (error) {
        setOpen(true);
        setStatus(false);
        setColor(false);
        setIsLoading(false);
        setMessage("Oops something went wrong " + error.message);
      }
    }
  };

  useEffect(() => {
    getAllData();
  }, [searchText]);

  useEffect(() => {
    // Retrieve the category ID from the URL
    const slideCategoryId = window.location.hash.substring(1);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: "smooth" });

    // After a brief timeout, scroll to the category section
    setTimeout(() => {
      const element = document.getElementById(slideCategoryId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  }, []);

  return (
    <Box position="relative">
      <Container>
        <Box mt={8}>
          <Typography variant="h3" fontWeight={600} color="#fff">
            Search Results For
          </Typography>
          <Typography variant="h3" fontWeight={600} color="#46344E" mt={1}>
            {`" ${searchText} "`}
          </Typography>
        </Box>
      </Container>

      <Container>
        {isLoading ? (
          <Grid container spacing={3} mt={1}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Grid item xs={12} key={index}>
                <Skeleton
                  variant="rounded"
                  sx={{ borderRadius: "20px" }}
                  width="100%"
                  height={250}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            {!noResults ? (
              <Box>
                {Object.entries(liveData).map(([rights, movies]) => (
                  <>
                    {movies.length !== 0 && movies ? (
                      <ResultsCard
                        key={rights}
                        RightName={rights}
                        MovieList={movies}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                      />
                    ) : null}
                  </>
                ))}
              </Box>
            ) : (
              <Box
                mb={3}
                mt={2}
                textAlign="left"
                bgcolor="#fff"
                p={4}
                borderRadius="20px"
                boxShadow={7}
              >
                <Grid xs={12}>
                  <Stack textAlign="center" py={3} spacing={2}>
                    <Typography variant="h5" fontWeight={600}>
                      We couldn't find any matches for your search. Please try
                      different keywords or check back later.
                    </Typography>
                  </Stack>
                </Grid>
              </Box>
            )}
          </>
        )}
      </Container>

      <Footer />
    </Box>
  );
}
