import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Link,
  Avatar,
} from "@mui/material";
import "./Homepage.css";
import { rightList } from "../../Variables/Variables";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Footer from "../../Components/Footer/Footer";
import { East } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import StackCards from "./StackCards";
import BlockChain from "../../Assets/Blockchain_Logo-removebg-preview.png";
import Web3Img from "../../Assets/web_3.0-removebg-preview.png";
import ReactPlayer from "react-player";
import {
  fetch_Media_mentions,
  fetch_Video_Link,
  fetch_endorsements,
  fetch_get_allaince_creds,
  fetch_get_content_buyers,
  fetch_get_deal_image,
  fetch_step_by_step,
} from "../../Utils/Services/HomePageServices";
import {
  fetch_get_active_movies,
  fetch_get_random_movies,
} from "../../Utils/Services/MovieService";
import DoubleArrowOutlinedIcon from "@mui/icons-material/DoubleArrowOutlined";
import MovieCard from "../../Components/Cards/MovieCard";
export default function Homepage() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [selectedRightName, setSelectedRightName] = useState(
    rightList[0]?.name || ""
  );
  const [rightMovies, setrightMovies] = useState([]);
  const [randomrightMovies, setRandomrightMovies] = useState([]);
  const BazaarUserAuth = localStorage.getItem("BazaarUserAuth");
  const [DealsDone, setDealsDone] = useState([]);

  const [stepBystep, setStepByStep] = useState([]);
  const [mediaMentions, setMediaMentions] = useState([]);
  const [endrosement, setEndrosement] = useState([]);
  const [videoLink, setVideoLink] = useState([]);
  const [allaince, setAllaince] = useState([]);
  const [contentBuy, setContentBuy] = useState([]);
  const [collect, setCollect] = useState([]);

  const sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext(); // Call the slickNext method to move to the next slide
  };

  // Event handler for moving the slider backward (to the previous slide)
  const handlePrev = () => {
    sliderRef.current.slickPrev(); // Call the slickPrev method to move to the previous slide
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const settings3 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings1 = {
    slidesToShow: 1,
    adaptiveHeight: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    rtl: true,
  };

  const settings6 = {
    slidesToShow: 1,
    adaptiveHeight: true,
    autoplay: true,
    arrows: false,
    speed: 500,
    rtl: false,
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const responseData = await fetch_get_deal_image("");
        if (!responseData.error) {
          setDealsDone(responseData.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    const getStepbyStep = async () => {
      try {
        const response = await fetch_step_by_step("");
        if (!response.error) {
          setStepByStep(response.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    const getMediaMentions = async () => {
      try {
        const response = await fetch_Media_mentions("");
        if (!response.error) {
          setMediaMentions(response.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    const getVideoLink = async () => {
      try {
        const response = await fetch_Video_Link("");
        if (!response.error) {
          setVideoLink(response.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    const getEndrosement = async () => {
      try {
        const response = await fetch_endorsements("");
        if (!response.error) {
          setEndrosement(response.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    const getcontentbuyers = async () => {
      try {
        const response = await fetch_get_content_buyers("");
        if (!response.error) {
          setContentBuy(response.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    const getallaincecreds = async () => {
      try {
        const response = await fetch_get_allaince_creds("");
        if (!response.error) {
          setAllaince(response.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };

    const gettopcollect = async () => {
      try {
        const response = await fetch_get_random_movies("");
        if (!response.error) {
          setCollect(response.data);
        }
      } catch (error) {
        console.log("Oops something went wrong " + error.message);
      }
    };
    gettopcollect();
    getallaincecreds();
    getcontentbuyers();
    getEndrosement();
    getMediaMentions();
    getStepbyStep();
    getData();
    getVideoLink();
  }, []);

  const TestimonialslideItems =
    endrosement &&
    endrosement.map((i, index) => {
      return (
        <Box width="100%" sx={{ bgcolor: "#fff", borderRadius: "20px" }} p={2}>
          <Box
            component={Card}
            sx={{ bgcolor: "#fff" }}
            boxShadow={0}
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {index % 2 !== 0 ? (
              <Box
                textAlign="justify"
                display="flex"
                flexDirection={{
                  lg: "row",
                  md: "row",
                  sm: "column",
                  xs: "column",
                }}
                gap={{ lg: 5, md: 4, sm: 2, xs: 2 }}
                p={1}
              >
                <img
                  src={i.images}
                  loading="lazy"
                  alt="test"
                  className="testimage"
                />
                <Stack spacing={2}>
                  <Typography>{i.content}</Typography>
                  <Typography variant="body1" fontWeight={600} color="#808080">
                    {i.names}
                  </Typography>
                  <Typography variant="body1" fontWeight={600} color="#FFC61A">
                    {i.designation}
                  </Typography>
                </Stack>
              </Box>
            ) : (
              <Box
                display="flex"
                textAlign="justify"
                flexDirection={{
                  lg: "row",
                  md: "row",
                  sm: "column-reverse",
                  xs: "column-reverse",
                }}
                gap={{ lg: 5, md: 4, sm: 2, xs: 2 }}
                p={1}
              >
                <Stack spacing={2}>
                  <Typography>{i.content}</Typography>
                  <Typography variant="body1" fontWeight={600} color="#808080">
                    {i.names}
                  </Typography>
                  <Typography variant="body1" fontWeight={600} color="#FFC61A">
                    {i.designation}
                  </Typography>
                </Stack>
                <img
                  src={i.images}
                  loading="lazy"
                  alt="test"
                  className="testimage"
                />
              </Box>
            )}
          </Box>
        </Box>
      );
    });

  const MedialideItems =
    mediaMentions &&
    mediaMentions?.map((i, index) => {
      return (
        <Card
          sx={{
            maxWidth: 300,
            height: "100%",
            boxShadow: 1,
            borderRadius: "20px",
          }}
        >
          <CardMedia
            component="img"
            height="200"
            image={i.mediaImage}
            alt={i.title}
            sx={{
              objectFit: "contain",
              mixBlendMode: "multiply",
            }}
            loading="lazy"
          />
          <CardContent>
            <Stack textAlign="left" spacing={2}>
              <Typography variant="body2" sx={{ color: "#808080" }}>
                {i?.mediaDate}
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#46344E" }}
                fontWeight={500}
              >
                {i?.mediaNames}
              </Typography>
              <Box display="flex" justifyContent="start">
                <Link
                  href={i?.mediaLink}
                  underline="none"
                  target="_blank"
                  rel="noopener"
                  sx={{
                    verticalAlign: "middle",
                    color: "#FFC61A",
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                >
                  Find Out More{" "}
                  <ArrowRightAltIcon
                    sx={{ verticalAlign: "middle", color: "#FFC61A" }}
                  />
                </Link>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      );
    });

  const VideoSlidesItem =
    videoLink &&
    videoLink.map((i, index) => {
      return (
        <Box p={4} component={Card} height="100%" borderRadius="20px">
          <ReactPlayer
            url={i.videoLink}
            controls={true}
            width="100%"
            height="300px"
          />
        </Box>
      );
    });

  const DealdoneItem =
    DealsDone &&
    DealsDone.map((i, index) => {
      return (
        <Box sx={{ maxWidth: 270 }}>
          <img
            src={i.images}
            loading="lazy"
            alt=""
            onClick={() =>
              navigate(
                `/dealdonedetail/${i.imageId}/${i.title
                  .replace(/\s+/g, "")
                  .toLowerCase()}`
              )
            }
            style={{
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              borderRadius: "14px",
              cursor: "pointer",
            }}
          />
        </Box>
      );
    });

  const getMoviesByRights = async () => {
    try {
      const responseData = await fetch_get_active_movies(
        "",
        "",
        "",
        "",
        selectedRightName,
        "",
        ""
      );
      if (!responseData.error) {
        setrightMovies(responseData.data);
      }
    } catch (error) {
      console.log("Oops something went wrong " + error.message);
    }
  };

  useEffect(() => {
    getMoviesByRights();
  }, [selectedRightName]);

  const handleTabClick = (rightName) => {
    setValue(rightList.findIndex((item) => item.right === rightName));
    setSelectedRightName(rightName);
  };

  useEffect(() => {
    // Function to shuffle array
    const shuffleArray = (array) => {
      let currentIndex = array.length,
        randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    };

    // Shuffle and slice the first 6 items
    const shuffledMovies = shuffleArray(rightMovies || []);
    const slicedMovies = shuffledMovies.slice(0, 4);

    setRandomrightMovies(slicedMovies);
  }, [rightMovies]);

  const gotoView = (right, rightName) => {
    navigate("/viewall", { state: { right: right, rightName: rightName } });
  };

  const [isAnimated, setIsAnimated] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          setIsAnimated(true);
          observer.unobserve(target.target);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box position="relative">
      <Container>
        <Box mt={8} mb={20} className="stacKcarosuelCon">
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Stack spacing={2} textAlign="left" width="100%">
                <Stack spacing={1} width="100%">
                  <Typography
                    fontWeight={600}
                    variant="h3"
                    sx={{
                      fontSize: {
                        lg: "42px",
                        md: "38px",
                        sm: "35px",
                        xs: "32px",
                      },
                    }}
                    color="#fff"
                  >
                    WORLDWIDE MARKETPLACE FOR CONTENT
                  </Typography>
                  <Typography
                    fontWeight={600}
                    variant="h3"
                    color="#46344E"
                    sx={{
                      fontSize: {
                        lg: "42px",
                        md: "38px",
                        sm: "35px",
                        xs: "32px",
                      },
                    }}
                  >
                    IP RIGHTS EXCHANGE
                  </Typography>
                </Stack>
                <Typography
                  variant="h5"
                  color="#000"
                  fontWeight={500}
                  fontFamily="Futura-Light-font"
                >
                  Blockchain-enabled platform ensures transparent rights and
                  perpetual royalties
                </Typography>
                <Box display="flex" flexDirection="row" gap={3} pt={1}>
                  <Button
                    onClick={() => {
                      if (BazaarUserAuth) {
                        navigate("/seller");
                      } else {
                        navigate("/signup");
                      }
                    }}
                    variant="contained"
                    sx={{
                      bgcolor: "#46344E",
                      fontWeight: 600,
                      color: "#fff",
                      ":hover": { bgcolor: "#46344E", color: "#fff" },
                      textTransform: "capitalize",
                    }}
                  >
                    Sell Rights
                  </Button>
                  <Button
                    onClick={() => navigate("/signup")}
                    variant="contained"
                    sx={{
                      bgcolor: "#46344E",
                      fontWeight: 600,
                      color: "#fff",
                      ":hover": { bgcolor: "#46344E", color: "#fff" },
                      textTransform: "capitalize",
                    }}
                  >
                    Buy Rights
                  </Button>
                </Box>
              </Stack>
              <Box
                py={3}
                display="flex"
                flexDirection="row"
                gap={1}
                textAlign="left"
                alignContent="center"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  color="#46344E"
                  fontFamily="Futura-Bold-font"
                >
                  Powered By
                </Typography>
                <img
                  src={BlockChain}
                  loading="lazy"
                  alt="block"
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "contain",
                    verticalAlign: "middle",
                  }}
                />
                <img
                  src={Web3Img}
                  loading="lazy"
                  alt="block"
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "contain",
                    verticalAlign: "middle",
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} position="relative">
              <Box>
                <StackCards />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box
          my={3}
          mt={{ lg: 10, md: 10, sm: 10, xs: 60 }}
          display="flex"
          flexDirection="row"
        >
          <Typography variant="h4" textAlign="left" fontWeight={550}>
            Deals{" "}
            <Typography
              component="span"
              variant="h4"
              textAlign="left"
              fontWeight={550}
              color="#fff"
            >
              Done
            </Typography>
          </Typography>
        </Box>
        <Box position="relative">
          <Box className="rightArrow" onClick={handlePrev}>
            <DoubleArrowOutlinedIcon
              sx={{ verticalAlign: "middle", color: "#fff", cursor: "pointer" }}
            />
          </Box>

          <Box className="leftArrow" onClick={handleNext}>
            <DoubleArrowOutlinedIcon
              sx={{ verticalAlign: "middle", color: "#fff", cursor: "pointer" }}
            />
          </Box>
          <Slider {...settings3} ref={sliderRef}>
            {DealdoneItem}
          </Slider>
        </Box>
      </Container>

      <Container>
        <Box mt={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={3} md={3} textAlign="left">
              <Box display="flex" flexDirection="row">
                <Typography variant="h4" textAlign="left" fontWeight={550}>
                  Explore
                  <Typography
                    component="span"
                    variant="h4"
                    textAlign="left"
                    fontWeight={550}
                    color="#fff"
                  >
                    {" "}
                    Rights
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              display="flex"
              justifyContent={{
                lg: "end",
                md: "end",
                sm: "center",
                xs: "center",
              }}
            >
              <Box maxWidth={{ lg: "100%", md: "100%", sm: "100%", xs: 350 }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="success"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="full width tabs example"
                >
                  {rightList &&
                    rightList.map((i, index) => {
                      return (
                        <Tab
                          label={i.name}
                          onClick={() => handleTabClick(i.right)}
                          sx={{
                            bgcolor: value === index ? "#46344E" : "#fff",
                            color: value === index ? "#fff" : "#46344E",
                            border:
                              value === index
                                ? "1px solid #46344E"
                                : "1px solid #fff",
                            borderRadius: value === index && "4px",
                            fontSize: "15px",
                            fontWeight: 600,
                            textTransform: "none",
                            marginRight: "15px",
                          }}
                          {...a11yProps(index)}
                        />
                      );
                    })}
                </Tabs>
              </Box>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid xs={12}>
              {rightList &&
                rightList.map((name, index) => {
                  return (
                    <TabPanel value={value} index={index}>
                      <Grid container spacing={2}>
                        {randomrightMovies?.map((i) => {
                          return (
                            <Grid
                              item
                              xs={6}
                              sm={6}
                              md={3}
                              lg={3}
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(
                                  `/livedetail/home/${
                                    i.activeMovieId
                                  }/${i.movieTitle
                                    .replace(/\s+/g, "")
                                    .toLowerCase()}`
                                )
                              }
                            >
                              <MovieCard i={i} showHeart={false} />
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Box display="flex" justifyContent="end" mt={2}>
                        <Button
                          onClick={() => gotoView(name.right, name.right)}
                          sx={{
                            color: "#46344E",
                            textTransform: "capitalize",
                            fontSize: "18px",
                            borderColor: "#808080",
                          }}
                        >
                          View All <East sx={{ verticalAlign: "middle" }} />
                        </Button>
                      </Box>
                    </TabPanel>
                  );
                })}
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box my={2} mt={8}>
          <Box display="flex" flexDirection="row">
            <Typography variant="h4" textAlign="left" fontWeight={550}>
              Step By Step To Create and Sell{" "}
              <Typography
                component="span"
                variant="h4"
                textAlign="left"
                fontWeight={550}
                color="#fff"
              >
                Your Rights
              </Typography>
            </Typography>
          </Box>

          <Grid container spacing={5} mt={1} ref={ref}>
            {stepBystep?.map((i, index) => {
              const slideAnimations = {
                0: "slideInLeft",
                1: "slideInUp",
                2: "slideInDown",
                3: "slideInRight",
              };

              const slide = slideAnimations[index] || "";
              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  className={`CreateCard ${isAnimated ? slide : ""}`}
                >
                  <Box
                    p={3}
                    component={Card}
                    bgcolor="#46344E"
                    height="100%"
                    boxShadow={5}
                    borderRadius="20px"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      gap={2}
                    >
                      <Box
                        display="flex"
                        justifyContent="start"
                        alignContent="start"
                        alignItems="start"
                      >
                        <img
                          src={i.icon}
                          alt="icon"
                          style={{
                            width: 40,
                            height: 40,
                          }}
                        />
                      </Box>
                      <Box
                        width="80%"
                        textAlign="left"
                        display="flex"
                        flexDirection="column"
                        gap={1}
                      >
                        <Typography variant="subtitle2" color="#FFC61A">
                          {i.stepNo}
                        </Typography>
                        <Typography fontWeight={600} variant="h6" color="#ffe">
                          {i.names}
                        </Typography>
                        <Typography variant="subtitle2" color="#fff">
                          {i.descriptions}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box my={2} mt={10} display="flex" flexDirection="row">
          <Typography variant="h4" textAlign="left" fontWeight={550}>
            Top Collections{" "}
            <Typography
              component="span"
              variant="h4"
              textAlign="left"
              fontWeight={550}
              color="#fff"
            >
              This Week
            </Typography>
          </Typography>
        </Box>

        <Box mt={3} mb={1}>
          <Grid container spacing={2}>
            {collect?.map((i) => {
              return (
                <Grid
                  className="topcards"
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  lg={3}
                  sx={{ cursor: "pointer" }}
                >
                  <Card
                    sx={{
                      boxShadow: 5,
                      borderRadius: "15px",
                      maxWidth: 385,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      image={i.moviePoster}
                      loading="lazy"
                      sx={{
                        width: "100%",
                        height: "350px",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                      }}
                    />
                    <CardContent>
                      <Box display="flex" flexDirection="column" gap={1.5}>
                        <Box
                          textAlign="left"
                          display="flex"
                          flexDirection="row"
                          gap={1}
                          alignContent="start"
                          alignItems="start"
                          justifyContent="start"
                        >
                          <Avatar
                            src={i.userProfile}
                            sx={{
                              width: 24,
                              height: 24,
                              verticalAlign: "middle",
                            }}
                          />
                          <Typography variant="subtitle2">
                            {i.userName ? i.userName : "Producer Bazaar"}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >
                          <Box
                            display="flex"
                            justifyContent="start"
                            textAlign="left"
                            flexDirection="column"
                            gap={1}
                          >
                            <Typography
                              variant="body1"
                              color="#101010"
                              fontWeight={600}
                            >
                              {i.movieTitle.charAt(0).toUpperCase() +
                                i.movieTitle.slice(1)}
                            </Typography>
                            <Typography variant="body2" color="#808080">
                              {i.sellingRights}
                            </Typography>
                            <Typography
                              variant="caption"
                              fontWeight={600}
                              color="#5B5561"
                            >
                              {i.productionName
                                ? i.productionName
                                : "Producer Bazaar"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>

      <Container>
        <Box py={1} textAlign="center" mt={10}>
          <Box
            py={3}
            textAlign="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Typography variant="h4" fontWeight={550}>
              Allaince{" "}
              <Typography
                component="span"
                variant="h4"
                textAlign="left"
                fontWeight={550}
                color="#fff"
              >
                Creds
              </Typography>
            </Typography>
          </Box>
          <Box
            p={4}
            component={Card}
            height="100%"
            boxShadow={5}
            borderRadius="20px"
          >
            <Grid container spacing={1} justifyContent="space-around">
              {allaince.map((i) => {
                return (
                  <Grid item xs={12} sm={4} md={2} lg={2}>
                    <Box>
                      <img
                        src={i.images}
                        loading="lazy"
                        alt=""
                        style={{
                          width: 150,
                          height: 150,
                          objectFit: "contain",
                          mixBlendMode:
                            i.title === "Brand Change"
                              ? "difference"
                              : "multiply",
                        }}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Container>

      <Container>
        <Box py={1} textAlign="center" mt={5}>
          <Box
            py={3}
            textAlign="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Typography variant="h4" fontWeight={550}>
              Content{" "}
              <Typography
                component="span"
                variant="h4"
                textAlign="left"
                fontWeight={550}
                color="#fff"
              >
                Buyers
              </Typography>
            </Typography>
          </Box>
          <Box
            p={4}
            component={Card}
            height="100%"
            boxShadow={5}
            borderRadius="20px"
          >
            <Grid container spacing={1} justifyContent="center" rowSpacing={3}>
              {contentBuy.map((i) => {
                return (
                  <Grid item xs={12} sm={6} md={3} lg={3}>
                    <Stack textAlign="center">
                      <Box>
                        <img
                          src={i.images}
                          loading="lazy"
                          alt=""
                          style={{
                            width: 200,
                            height: 150,
                            objectFit: "contain",
                            mixBlendMode:
                              i.title === "Brand Change"
                                ? "difference"
                                : "multiply",
                          }}
                        />
                      </Box>
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Container>

      <Box py={1} mt={10}>
        <Box
          py={3}
          textAlign="center"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Typography variant="h4" fontWeight={550}>
            Media{" "}
            <Typography
              component="span"
              variant="h4"
              textAlign="left"
              fontWeight={550}
              color="#fff"
            >
              Mentions
            </Typography>
          </Typography>
        </Box>
        <Box>
          <Slider {...settings3}>{MedialideItems}</Slider>
        </Box>
      </Box>

      <Box className="Testimonials" pb={1} mt={5}>
        <Container>
          <Box
            py={3}
            textAlign="center"
            display="flex"
            flexDirection="row"
            justifyContent="center"
          >
            <Typography variant="h4" fontWeight={550}>
              Endorsements
            </Typography>
          </Box>
          <Box py={1}>
            <Slider {...settings1}>{TestimonialslideItems}</Slider>
          </Box>
        </Container>
      </Box>

      <Container>
        <Box py={1} textAlign="center" mt={5}>
          <Slider {...settings6}>{VideoSlidesItem}</Slider>
        </Box>
      </Container>

      <Footer />
    </Box>
  );
}
