import React, { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Spinner from "../Components/Spinner/Spinner";
import NavBar from "../Components/NavBar/NavBar";
import Homepage from "../Pages/HomePage/Homepage";
import DetailedBlog from "../Pages/About/Blog";
import SocialSign from "../Pages/SignUp/SocialSign";
import MovieResults from "../Pages/SearchBar/MovieResults";

const Signin = lazy(() => import("../Pages/SignIn/Signin"));
const SignUp = lazy(() => import("../Pages/SignUp/SignUp"));
const MarketPlace = lazy(() => import("../Pages/MarketPlace/MarketPlace"));
const About = lazy(() => import("../Pages/About/About"));
const BuyerProfile = lazy(() => import("../Pages/Buyer/BuyerProfile"));
const SellerProfile = lazy(() => import("../Pages/Seller/SellerProfile"));
const UploadForm = lazy(() => import("../Pages/Seller/UploadForm"));
const ViewAllPage = lazy(() => import("../Pages/MarketPlace/ViewAllPage"));
const SignupConfirmation = lazy(() =>
  import("../Pages/SignUp/SignupConfirmation")
);
const StaticMovieDetail = lazy(() =>
  import("../Components/Cards/StaticMovieDetail")
);
const DealDoneDetail = lazy(() => import("../Components/Cards/DealDoneDetail"));
const MovieDetailCard = lazy(() =>
  import("../Components/Cards/MovieDetailCard")
);

export const startUrl = `/`;

function Routes() {
  const isLoggedIn = localStorage.getItem("BazaarUserAuth");

  return (
    <>
      <NavBar />
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              paddingTop: 50,
            }}
          >
            <Spinner />
          </div>
        }
      >
        {useRoutes([
          {
            path: startUrl,
            element: <Homepage />,
          },
          {
            path: "*",
            element: <Navigate to="/" />,
          },
          {
            path: "signin",
            element: <Signin />,
          },
          {
            path: "signup",
            element: <SignUp />,
          },
          {
            path: "socialsign",
            element: <SocialSign />,
          },
          {
            path: "signupconfirmation",
            element: <SignupConfirmation />,
          },
          {
            path: "marketplace",
            element: <MarketPlace />,
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "buyer",
            element: isLoggedIn ? <BuyerProfile /> : <Navigate to="/" />,
          },
          {
            path: "seller",
            element: isLoggedIn ? <SellerProfile /> : <Navigate to="/" />,
          },
          {
            path: "upload",
            element: isLoggedIn ? <UploadForm /> : <Navigate to="/" />,
          },
          {
            path: "viewall",
            element: <ViewAllPage />,
          },
          {
            path: "detail/:path/:id/:name",
            element: <StaticMovieDetail />,
          },
          {
            path: "livedetail/:path/:id/:name",
            element: <MovieDetailCard />,
          },
          {
            path: "dealdonedetail/:id/:name",
            element: <DealDoneDetail />,
          },
          {
            path: "/blogs",
            element: <DetailedBlog />,
          },
          {
            path: "/movieresults",
            element: <MovieResults />,
          },
        ])}
      </Suspense>
    </>
  );
}

export default Routes;
